<template>
  <div class="settingMain">
    <NavbarBack :title="'จัดการความสัมพันธ์'" />
    <div class="sellersList">
      <div class="item" v-for="(item, index) of sellers" :key="index">
        <div>
          <b>{{ item.seller.name }}</b>
          <div class="detail">{{ item.seller.address }}</div>
        </div>
        <div>
          <div
            @click="toggleClick(item.id, true)"
            v-if="!item.hidden"
            class="btn actionBtn hide"
          >
            ซ่อน
          </div>
          <div
            @click="toggleClick(item.id, false)"
            v-if="item.hidden"
            class="btn actionBtn show"
          >
            แสดง
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarBack from "@/components/NavbarBack";
import { GET, PUT } from "@/utils/http";
import errorCode from "@/utils/errorCode";

export default {
  components: { NavbarBack },
  data() {
    return {
      sellers: [],
      prevRoute: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
      vm.getSellers();
    });
  },
  methods: {
    async toggleClick(partnerId, hidden) {
      try {
        window.loading(true);

        const reqBody = {
          hidden,
        };

        const { status, body } = await PUT(
          `/api/partners/${partnerId}/hide`,
          reqBody
        );
        window.loading(false);

        if (status === 403) {
          if (body.message) {
            await window.$alert(body.message);
            this.getSellers();
            return;
          }
        }

        if (status === 200) {
          this.getSellers();
          return;
        }

        throw status;
      } catch (err) {
        window.loading(false);
        console.error("error partners/${partnerId}/hide: ", err);
        await window.$alert(`${errorCode(204).message}<br>(204)`);
        this.$router.back();
      }
    },
    async getSellers() {
      window.loading(true);

      const { status, body } = await GET(
        `/api/shops/${window.$permission.myShop.id}/partners/seller/all?limit=500`
      );
      window.loading(false);

      if (status !== 200) {
        await window.$alert("พบข้อผิดพลาด กรุณาลองใหม่ภายหลัง");
        this.$router.back();
        return;
      }

      this.sellers = body.data;
    },
  },
};
</script>
<style lang="scss" scoped>
.settingMain {
  // padding-top: 60px;
  background: #fff;
  height: 100%;
}

.title {
  // text-align: center;
  font-size: 15px;
  padding: 10px 0 5px 15px;
  font-weight: bold;
}

.content {
  overflow: auto;
}

.sellersList {
  padding: 50px 10px 10px 10px;
}

.item {
  // margin: 10px 10px;
  display: flex;
  padding: 15px 14px;
  // border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid #d8d8d8;
  min-height: 45px;
  user-select: none;
  box-shadow: 0px 2px 1px -1px rgb(155 155 155 / 21%);
  gap: 5px;
  b {
    display: block;
    margin-bottom: 6px;
  }
  .detail {
    font-size: 15px;
    line-height: 24px;
  }
  > div:nth-child(2) {
    width: 80px;
  }
  img {
    width: 15px;
  }
}

.actionBtn {
  color: #fff;
  padding: 10px 10px;

  &.show {
    background: var(--primary-color);
  }
  &.hide {
    background: var(--danger-color);
  }
}
</style>